import ky from 'ky';

class API {
  constructor() {
    if(!process.env.GATSBY_BACKEND_URL) {
      throw new Error('GATSBY_BACKEND_URL not set');
    }

    this.client = ky.create({
      prefixUrl: process.env.GATSBY_BACKEND_URL,
    });
  }

  requestOrder({
    selectedInternetProduct,
    selectedTvProduct,
    selectedPhoneProduct,
    selectedExtraProduct,
    contact: {
      bedrijfsnaam,
      kvk,
      straat,
      huisnummer,
      toevoeging,
      postcode,
      plaats,
      voornaam,
      tussenvoegsel,
      achternaam,
      telefoonnummer,
      emailadres
    }
  }) {
    return this.client.post('order', {
      json: {
        internetProductId: selectedInternetProduct,
        tvProductId: selectedTvProduct,
        phoneProductId: selectedPhoneProduct,
        extraProductId: selectedExtraProduct,
        contact: {
          bedrijfsnaam,
          kvk,
          straat,
          huisnummer,
          toevoeging,
          postcode,
          plaats,
          voornaam,
          tussenvoegsel,
          achternaam,
          telefoonnummer,
          emailadres
        }
      }
    })
    .json()
  }

}

export const api = new API();