export const setWithExpiry = (key: any, value: any, ttl: number) => {
  if(typeof window === 'undefined') {
    return;
  }

	const now = new Date()

	const item = {
		value: value,
		expiry: now.getTime() + ttl,
	}
	localStorage.setItem(key, JSON.stringify(item))
}

export const getWithExpiry = (key: any) => {
  if(typeof window === 'undefined') {
    return undefined;
  }

	const itemStr = localStorage.getItem(key)
	if (!itemStr) {
		return null
	}
	const item = JSON.parse(itemStr)
	const now = new Date()
	if (now.getTime() > item.expiry) {
		localStorage.removeItem(key)
		return null
	}
	return item.value
}